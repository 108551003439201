.clear-filter[filter-color="blue"] {
  background: rgba($black-color,.20);
}
[data-background-color="blue"]{
    background-color: $info-color;
}

.clear-filter[filter-color="black"] {
  background: rgba(0,0,0,.25);
}


.section-nucleo-icons .icons-container i:nth-child(6) {
  color: $info-color;
}
.page-header{
  &.page-header-small{
    min-height: 60vh;
    max-height: 640px;
  }
}
